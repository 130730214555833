// Packages
import React from "react";
import { graphql } from "gatsby";

// Types
import { IGatsbyImageData } from "gatsby-plugin-image";
import type { SiteMetadata, Location } from "~types/Gatsby";
import type { Product } from "~types/Shopify";

// Components
import Layout from "~components/layouts/Layout";
import P from "~components/typography/P";
import Hero from "~components/sections/Hero";
import SubTitle from "~components/typography/SubTitle";
import Ul from "~components/typography/Ul";
import Button from "~components/common/Button";
import H2 from "~components/typography/H2";
import Input from "~components/forms/elements/Input";
import Datenschutz from "~components/forms/elements/Datenschutz";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
	imageHeader: IGatsbyImageData;
	products: {
		nodes: Product[];
	};
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	const { products } = data;

	return (
		<Layout
			location={location}
			title="Gutschein"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
			noIndex
		>
			<Hero element={<Form />} alt="" align="start">
				<SubTitle className="mb-2">Stammkundenrabatt!</SubTitle>

				<H2 className="mb-4">Jetzt 100,- € sichern</H2>

				<Ul
					items={[
						"2-3 Semester weniger studieren",
						"Bessere Leistungen in Klausuren",
						"Aktivierung Deiner vollen Brainpower",
						"100-200% schneller Lesen und dabei X-Fach mehr merken und behalten",
						"Erhöhtes Textverständnis und Inhalte besser verstehen",
						"Prüfungsangst überwinden",
						"Intelligenter Fachwissen aneignen",
					]}
					pretty
					className="mb-3"
				/>

				<P className="mb-3">
					Hiermit bekommst Du eine einmalige Gelegenheit kostenlos an meinem Workshop: &ldquo;SpeedReading&ldquo; teil
					zunehmen.
				</P>

				<P className="mb-3">
					Lerne die Geheimnisse der Elite Universitäten kennen und entfalte Dein volles Potential im Studentenalltag
					oder im Berufsalltag.
				</P>
				<P className="mb-3">Schnell sein lohnt sich auch hier.</P>
				<P className="mb-3">Klicke auf die Homepage und trag Dich bitte verbindlich ein.</P>
				<P className="mb-10">Ich freue mich auf dieses außergewöhnliche Event mit dir.</P>
			</Hero>
			{/* <Container id="workshops">
				<div className="text-center">
					<SubTitle className="mb-2">Workshop buchen</SubTitle>
					<H2 display className="mb-16">
						Unser aktuelles Workshop-Angebot
					</H2>
				</div>

				{products.nodes.length > 0 ? (
					<Grid cols={3}>
						{products.nodes.map((product) => (
							<ProductCard key={product.title} product={product} />
						))}
					</Grid>
				) : (
					<Alert type="information" title="Keine Workshops gefunden" message={`Aktuell werden weiteren Workshop geplant. Komme gerne später wieder oder buche unseren Newsletter für weitere Informationen.`} />
				)}
			</Container> */}
		</Layout>
	);
}

export const Form = () => {
	return (
		<div className="py-8 md:py-16">
			<form name="Einladung" method="POST" data-netlify="true" action="/anfrage-gesendet/" className="space-y-4">
				<input type="hidden" name="form-name" value="Einladung" />
				<Input type="text" label="Vorname" id="vorname" name="Vorname" placeholder="Dein Vorname" required />
				<Input type="text" label="Nachname" id="nachname" name="Nachname" placeholder="Dein Nachname" required />

				<Input
					type="email"
					label="E-Mail"
					id="email"
					name="E-Mail"
					placeholder="Deine E-Mailadresse"
					autoComplete="email"
					required
				/>

				<div className="pt-3">
					<Button submit text="100,- € Rabatt" className="mt-3 mb-2 w-full" />
					<Datenschutz />
				</div>
			</form>
		</div>
	);
};

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		imageHeader: file(relativePath: { eq: "pages/einladung/einladung_header.jpg" }) {
			childImageSharp {
				gatsbyImageData(quality: 90)
			}
		}
	}
`;
